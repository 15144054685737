import * as React from "react"
import { graphql, Link } from "gatsby"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Seo from "../components/seo"

import LargeX from "../images/large-x.svg"

import Markdown from "react-markdown"
import { sliceIntoChunks } from "../helpers/functions"

import Download from "../images/download.svg"
import ContentRenderer from "../components/ContentRenderer"

const IndustryPage = ({ data }) => {
  const page = data.allStrapiIndustries.edges[0].node
  return (
    <Layout>
      <Seo title={page.Title} />

      {/* <ContentRenderer zone={product.dyanmiczone} /> */}
      <section className="industry-hero">
        <div className="blue-line-container">
          <div className="container industry-hero__inner">
            <div className="content-container">
              <h1 className="industry-hero__title">{page.Title}</h1>

              {page.Subtitle && (
                <p className="industry-hero__subtitle">{page.Subtitle}</p>
              )}
            </div>
            <div className="industry-hero__text">
              <Markdown children={page.LongDescription} />
            </div>
            <img
              src={page.verticalImage?.url}
              alt={page.verticalImage?.alternativeText}
              className="industry-hero__image-vertical"
            />
            <img
              src={page.horizontalImage?.url}
              alt={page.horizontalImage?.alternativeText}
              className="industry-hero__image-horizontal"
            />
          </div>
        </div>
        <img src={LargeX} className="industry-hero__background-image" />
      </section>

      <section className="industry-main">
        <div className="blue-line-container">
          <div className="container industry-main__inner">
            <div className="content-container">
              <h2 className="industry-main__title">{page.applicationIntro}</h2>
              <Markdown children={page.applicationText} />
              <Link to="/products" className="primary-btn btn-arrow">
                <span>View our products</span>
              </Link>
            </div>
            <div className="industry-main__grid">
              <div className="grid-container">
                {page.Applications.map(application => (
                  <div className="industry-main__item">
                    <h5>{application.Name}</h5>
                    <div className="greenline"></div>
                    <Markdown children={application.Description} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="industry-guides">
        <div className="container industry-guides__inner">
          <h2>Automotive Plastics Guide</h2>
          <div className="content-container">
            <table className="industry-guides__table">
              <thead>
                <tr>
                  <th className="industry-guides__table-heading">
                    Application
                  </th>
                  <th className="industry-guides__table-heading">
                    Requirements
                  </th>
                  <th className="industry-guides__table-heading">
                    Suggested Plastics
                  </th>
                  <th className="industry-guides__table-heading">
                    Product Spec Sheets
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="industry-guides__table-row">
                  <td className="industry-guides__table-cell">
                    <span className="industry-guides__table-celltitle">
                      Application
                    </span>
                    <span className="industry-guides__table-celltext">
                      Seatbelt Components
                    </span>
                  </td>
                  <td className="industry-guides__table-cell">
                    <span className="industry-guides__table-celltitle">
                      Requirements
                    </span>
                    <span className="industry-guides__table-celltext">
                      Wear Resistance/Lubricity, Good Strength, Impact
                      Resistance
                    </span>
                  </td>
                  <td className="industry-guides__table-cell">
                    <span className="industry-guides__table-celltitle">
                      Suggested Plastics
                    </span>
                    <span className="industry-guides__table-celltext">
                      PA6, PA66, ABS, PC-ABS, PC
                    </span>
                  </td>
                  <td className="industry-guides__download-cell">
                    <a className="industry-guides__download-link" href="/">
                      <span className="industry-guides__download-text">
                        Download Quick Guide
                      </span>
                      <img
                        src={Download}
                        className="industry-guides__download-icon"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section> */}

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allStrapiIndustries(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          Applications {
            Name
            Description
          }
          Icon {
            alternativeText
            url
          }
          LongDescription
          ShortDescription
          Title
          slug
          horizontalImage {
            alternativeText
            url
          }
          verticalImage {
            alternativeText
            url
          }
          Subtitle
          applicationIntro
          applicationText
        }
      }
    }
  }
`

export default IndustryPage
